<template>
  <!-- <v-container id="dashboard" fluid tag="section"> -->
  <v-row>
    <v-col cols="12">
      <base-material-card color="success" inline title="Home" class="px-5 py-3" />
    </v-col>
  </v-row>
  <!-- </v-container> -->
</template>
<script>
export default {
  name: "Home",
  data() {
    return {}
  }
}
</script>
<style></style>
